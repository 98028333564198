import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Get in touch</h2>
    <p>Drop me an <a href="mailto:websubmission@rossknight.co.uk">E-mail</a> or find me on <a href="https://www.linkedin.com/in/rossknight/">LinkedIn</a>. Note: I made a life choice sometime ago to actively refrain from posting much on Social Networks</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      