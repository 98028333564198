import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>About</h2>
    <blockquote>
  <p>Based in Edinburgh, Scotland, I started my development and eCommerce journey in the year 2000 when I managed a small team of developers in a startup agency.</p>
    </blockquote>
    <p>Further to a successful internal "print on demand" eCommerce project in 2005, we pivoted the business to become a manufacturer / merchant and I took ownership in 2007. Following on from this I grew the eCommerce property to be a market leader and became increasingly involved with the technical aspects of complex third party integrations.</p>
    <p>Subsequently I provided eCommerce consultancy on this subject for SMEs and in 2012 I co-founded one of UK's leading Magento agencies where I was the CTO for 5 years. In this position I managed a development team responsible for Global Enterprise level solutions. Deciding to resign in April 2017 I initially provided consultancy services to a select group of merchants whereby I assisted with developing eCommerce strategies and the implementation of best practice development processes. I now hold the postitions noted above.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      